import {Icon} from "@iconify/react"
import {Box, Container, Grid, Link, Stack, Typography} from "@mui/material"
import React from "react"

const Footer = () => {
  return (
    <Box component="footer" sx={{bgcolor: "grey.900", color: "white", py: "4rem"}}>
      <Container sx={{maxWidth: {xl: "1536px"}}}>
        <Grid container spacing={6} mb={"3rem"}>
          <Grid item xs={12} md={4}>
            <Typography color="white" variant="h6" fontWeight="bold" mb={"1.5rem"}>
              إدراك
            </Typography>
            <Typography color="white" mb={"1.5rem"}>
              منصة قانونية متخصصة لتسهيل عمل القانونيين{" "}
            </Typography>
            <Stack spacing={3}>
              <Stack direction="row" spacing={2} alignItems="center" color="white">
                <Icon icon="mdi:map-marker" width={20} />
                <Typography color="white">الطائف, المملكة العربية السعودية</Typography>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center" color="white">
                <Icon icon="mdi:email" width={20} />
                <Link
                  href="mailto:info@edrak.sa"
                  sx={{color: "white", "&:hover": {color: "primary.main"}}}
                >
                  info@edrak.sa
                </Link>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center" color="white">
                <Icon icon="mdi:phone" width={20} />
                <Link
                  href="tel:+966500000000"
                  sx={{color: "white", "&:hover": {color: "primary.main"}}}
                  dir="ltr"
                >
                  +966 50 000 0000
                </Link>
              </Stack>
            </Stack>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={4}>
            <Typography color="white" variant="h6" fontWeight="bold" mb={"1.5rem"}>
              روابط سريعة
            </Typography>
            <Stack spacing={3}>
              {[
                {href: "#features", text: "المميزات"},
                {href: "#pricing", text: "الأسعار"},
                {href: "/terms", text: "الشروط والأحكام"},
                {href: "/privacy", text: "سياسة الخصوصية"}
              ].map(link => (
                <Link
                  key={link.href}
                  href={link.href}
                  sx={{color: "white", "&:hover": {color: "primary.main"}}}
                >
                  {link.text}
                </Link>
              ))}
            </Stack>
          </Grid>

          {/* Legal Info */}
          <Grid item xs={12} md={4}>
            <Typography color="white" variant="h6" fontWeight="bold" mb={"1.5rem"}>
              معلومات قانونية
            </Typography>
            <Stack spacing={3} color="white">
              <Typography color="white">
                مؤسسة مسجلة في المملكة العربية السعودية <br />
                سجل تجاري رقم: 7021279422{" "}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box
          sx={{
            borderTop: 1,
            borderColor: "grey.800",
            pt: 4,
            textAlign: "center"
          }}
        >
          <Typography color="white">
            © {new Date().getFullYear()} إدراك - جميع الحقوق محفوظة
          </Typography>
          <Typography color="white" variant="body2" mt={1}>
            تطوير وتشغيل بواسطة شركة إدراك للحلول التقنية | المملكة العربية السعودية
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
